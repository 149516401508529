import {
    COMPANY_MY
} from '../actions/types';

const initialState = {
    myCompany: [],
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPANY_MY:
            return{
                ...state,
                myCompany: payload
            }
            break;
            
        default:
            return state;
    }
}
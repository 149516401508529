/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
import { createCompany, updateCompany, getMyCompany } from '../../../actions/company';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import FormInput from "./FormInput";

class Profile extends React.Component {
  constructor(props){
    super(props)
    this.state = { 
      defaultLocation: '',
      companyName: '',
      nip: '',
      address: '',
      postalCode: '',
      city: '',
      bank: '',
      bankAccount: '',
      logo: '',
      editMode: false,
      copyData: null
    }

    this.setInputValue = this.setInputValue.bind(this)
  }

  componentDidMount(){
    this.loadData()
  }

  async loadData(){
    await this.props.getMyCompany()
    if(this.props.myCompany.nip){
      this.setState(this.props.myCompany)
    }
  }

  setInputValue(k,v){
    this.setState({
      [k]: v
    })
  }

  setEditMode(){
    if(!this.state.editMode)
      this.setState({copyData: this.state, editMode: !this.state.editMode})
    else{
      this.setState(this.state.copyData)
    }
  }

  saveData(){
    if(typeof this.props.myCompany.nip === 'undefined'){
      this.props.createCompany(this.state)
    }else{
      this.props.updateCompany(this.state)
    }
    this.setState({editMode: !this.state.editMode})
    this.loadData()
  }

  render() {
    return (
      <>
        <ProfileHeader />
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col className="order-xl-1" xl="8">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edytuj profil</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {
                        !this.state.editMode ? 
                          <Button
                            color="primary"
                            onClick={() => this.setEditMode()}
                            size="sm"
                          >
                            Edytuj
                          </Button>
                        :
                          <>
                            <Button
                              color="danger"
                              onClick={() => this.setEditMode()}
                              size="sm"
                            >
                              Anuluj
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.saveData()}
                              size="sm"
                            >
                              Zapisz
                            </Button>
                          </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="heading-small text-muted mb-4">Logo</h6>
                  <div className="pl-lg-4">
                    {
                      this.state.editMode ? 
                        <Input 
                          type="text"
                          value={this.state.logo}
                          onChange={e=>this.setInputValue('logo', e.target.value)} />
                        :<img src={this.state.logo} alt="company-logo" style={{width: '220px'}} />
                    }
                  </div>
                  <hr className="my-4" />

                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Dane podstawowe
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormInput 
                            label='Nazwa firmy' 
                            inputID='input-companyName'
                            editMode={this.state.editMode}
                            value={this.state.companyName}
                            stateKey="companyName"
                            setInputValue={this.setInputValue} />
                        </Col>
                        <Col lg="12">
                          <FormInput 
                            label='NIP' 
                            inputID='input-nip'
                            editMode={this.state.editMode}
                            value={this.state.nip}
                            stateKey="nip"
                            setInputValue={this.setInputValue} />
                        </Col>
                        <Col lg="12">
                          <FormInput 
                            label='Lokalizacja (nagłówek)' 
                            inputID='input-defaultLocation'
                            editMode={this.state.editMode}
                            value={this.state.defaultLocation}
                            stateKey='defaultLocation'
                            setInputValue={this.setInputValue} />
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      Dane adresowe
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormInput 
                            label='Adres' 
                            inputID='input-address'
                            editMode={this.state.editMode}
                            value={this.state.address}
                            stateKey='address'
                            setInputValue={this.setInputValue} />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormInput 
                            label='Kraj' 
                            inputID='input-country'
                            editMode={this.state.editMode}
                            value={this.state.country}
                            stateKey='country'
                            setInputValue={this.setInputValue} />
                        </Col>
                        <Col lg="4">
                          <FormInput 
                            label='Miasto' 
                            inputID='input-city'
                            editMode={this.state.editMode}
                            value={this.state.city}
                            stateKey='city'
                            setInputValue={this.setInputValue} />
                        </Col>
                        <Col lg="4">
                          <FormInput 
                            label='Kod pocztowy' 
                            inputID='input-postalCode'
                            editMode={this.state.editMode}
                            value={this.state.postalCode}
                            stateKey='postalCode'
                            setInputValue={this.setInputValue} />
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">Dane bankowe</h6>
                    <div className="pl-lg-4">
                    <Row>
                        <Col md="12">
                          <FormInput 
                            label='Nazwa banku' 
                            inputID='input-bank'
                            editMode={this.state.editMode}
                            value={this.state.bank}
                            stateKey='bank'
                            setInputValue={this.setInputValue} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormInput 
                            label='Numer konta' 
                            inputID='input-bankAccount'
                            editMode={this.state.editMode}
                            value={this.state.bankAccount}
                            stateKey='bankAccount'
                            setInputValue={this.setInputValue} />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStatoToProps = state => ({
  myCompany: state.companies.myCompany
});

export default connect(
  mapStatoToProps,
  { createCompany, updateCompany, getMyCompany }
)(Profile);

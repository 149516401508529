import React from "react";
import { connect } from 'react-redux';
import {getInvoicesList} from '../../../actions/invoice';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.js";

const monthLabels = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      monthInvoices: 0,
      monthPrice: 0,
      monthNettoPrice: 0,
      fullPrice: 0,
      fullNettoPrice: 0,
      incomeChart: [],
      incomeChartNetto: [],
      invoiceCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidMount(){
    this.loadData()
  }

  async loadData(){
    await this.props.getInvoicesList();
    this.countData()
  }
  countData(){
    const d = new Date();
    let month = d.getMonth();

    let fullPrice = 0;
    let monthPrice = 0;
    let monthInvoices = 0;
    let monthNettoPrice = 0;
    let fullNettoPrice = 0;

    let incomeArr = [0,0,0,0,0,0,0,0,0,0,0,0];
    let incomeNettoArr = [0,0,0,0,0,0,0,0,0,0,0,0];
    let invoiceCountArr = [0,0,0,0,0,0,0,0,0,0,0,0];

    this.props.invoicesList.forEach(el => {
      let invoiceMonth = new Date(el.creationDate).getMonth()
      if(month === invoiceMonth)
        monthInvoices++;
      
      invoiceCountArr[invoiceMonth] = invoiceCountArr[invoiceMonth] + 1;

      el.products.forEach(product => {
        fullPrice += Number(product.brutto);
        fullNettoPrice += Number(product.netto)
        if(month === invoiceMonth){
          monthNettoPrice += Number(product.netto)
          monthPrice += Number(product.brutto);
        }
        incomeArr[invoiceMonth] =  Number((Math.round(( Number(incomeArr[invoiceMonth]) + Number(product.brutto) ) * 100) / 100).toFixed(2))
        incomeNettoArr[invoiceMonth] = Number((Math.round(( Number(incomeNettoArr[invoiceMonth]) + Number(product.netto) ) * 100) / 100).toFixed(2))
      })
    });
    // console.log(this.incomeObj(incomeArr))
    this.setState({
      monthInvoices: monthInvoices,
      monthPrice: monthPrice,
      monthNettoPrice: monthNettoPrice,
      fullPrice: fullPrice,
      fullNettoPrice: fullNettoPrice,
      incomeChart: incomeArr,
      incomeChartNetto: incomeNettoArr,
      invoiceCount: invoiceCountArr
    })
  }

  incomeChartMet = canvas => {
    return this.incomeObj(this.state.activeNav === 2 ? this.state.incomeChart : this.state.incomeChartNetto);
  }

  incomeObj(data){
    const d = new Date();
    let month = d.getMonth();
    let labels = monthLabels.slice(0, month+1)
    let obj = {
      labels: labels,
      datasets: [
        {
          label: "Performance",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };
    obj.datasets[0].data = data
    return obj;
  }

  invoiceCount = () => {
    const d = new Date();
    let month = d.getMonth();
    let labels = monthLabels.slice(0, month+1)
    return {
      labels: labels,
      datasets: [
        {
          label: "Sales",
          data: this.state.invoiceCount,
          maxBarThickness: 10
        }
      ]
    }
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
    });
  };
  render() {
    return (
      <>
        <CardsHeader 
          name="Default" 
          parentName="Dashboards" 
          monthInvoices={this.state.monthInvoices} 
          monthNettoPrice={this.state.monthNettoPrice} 
          monthPrice={this.state.monthPrice} 
          fullPrice={this.state.fullPrice} 
          fullNettoPrice={this.state.fullNettoPrice} />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="6">
              <Card className="bg-default">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        {this.state.activeNav === 1 ? 'Netto':'Brutto'}
                      </h6>
                      <h5 className="h3 text-white mb-0">Zysk</h5>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem className="mr-2 mr-md-0">
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="">Netto</span>
                            {/* <span className="d-md-none">M</span> */}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="">Brutto</span>
                            {/* <span className="d-md-none">W</span> */}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.incomeChartMet}
                      options={chartExample1.options}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        {/* Performance */}
                      </h6>
                      <h5 className="h3 mb-0">Liczba Faktur</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={this.invoiceCount}
                      options={chartExample2.options}
                      className="chart-canvas"
                      id="chart-bars"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">To do list</h5>
                </CardHeader>
                <CardBody className="p-0">
                  <ListGroup data-toggle="checklist" flush>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-success checklist-item-checked">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Call with Dave
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-success">
                            <input
                              className="custom-control-input"
                              defaultChecked
                              id="chk-todo-task-1"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-1"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div className="checklist-item checklist-item-warning">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Lunch meeting
                          </h5>
                          <small>10:30 AM</small>
                        </div>
                        <div>
                          <div className="custom-control custom-checkbox custom-checkbox-warning">
                            <input
                              className="custom-control-input"
                              id="chk-todo-task-2"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="chk-todo-task-2"
                            />
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xl="7">
              <Row>
                <div className="col">
                  <Card>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">Light table</h3>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="name" scope="col">
                            Project
                          </th>
                          <th className="sort" data-sort="budget" scope="col">
                            Budget
                          </th>
                          <th className="sort" data-sort="status" scope="col">
                            Status
                          </th>
                          <th
                            className="sort"
                            data-sort="completion"
                            scope="col"
                          >
                            Completion
                          </th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody className="list">
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/bootstrap.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Argon Design System
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$2500 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-warning" />
                              <span className="status">pending</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">60%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value="60"
                                  color="warning"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xl="8">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Social traffic</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                      <th scope="col">Visitors</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>1,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              color="gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>5,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">70%</span>
                          <div>
                            <Progress
                              max="100"
                              value="70"
                              color="gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Google</th>
                      <td>4,807</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">80%</span>
                          <div>
                            <Progress
                              max="100"
                              value="80"
                              clor="gradient-primary"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Instagram</th>
                      <td>3,678</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">75%</span>
                          <div>
                            <Progress
                              max="100"
                              value="75"
                              color="gradient-info"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">twitter</th>
                      <td>2,645</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              color="gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  invoicesList: state.invoices.invoicesList
})

export default connect(
  mapStateToProps,
  { getInvoicesList }
)(Dashboard);

import {
    PRODUCT_ADD,
    PRODUCT_UPDATE,
    PRODUCT_DELETE,
    PRODUCT_LIST
} from '../actions/types';

const initialState = {
    productsList: []
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case PRODUCT_ADD:
            return{
                ...state,
            }
            break;
    
        case PRODUCT_UPDATE:
            return{
                ...state
            }

        case PRODUCT_DELETE:
            return{
                ...state
            }

        case PRODUCT_LIST:
            return{
                ...state,
                productsList: payload
            }

        default:
            return state;
    }
}
import React from 'react';
// reactstrap components
import {
    FormGroup,
    Input
  } from "reactstrap";

class FormInput extends React.Component{

    render() {
        return (
        <FormGroup>
            <label
              className="form-control-label"
              htmlFor={this.props.inputID}
            >
                {this.props.label}
            </label>
            {
                this.props.editMode ? 
                    <Input
                        id={this.props.inputID}
                        placeholder={this.props.label}
                        type="text"
                        value={this.props.value}
                        onChange={e => this.props.setInputValue(
                            this.props.stateKey,
                            e.target.value
                        )}
                    /> 
                : 
                    <div>
                        {this.props.value ? this.props.value : '-'}
                    </div>
            }
          </FormGroup>
        )
    }
}

export default FormInput;
import api from '../utils/api';
import {
    CUSTOMERS_LIST
} from './types';

export const getCustomersList = () => async dispatch => {
    try {
        const res = await api.get('/customers/list');
        dispatch({
            type: CUSTOMERS_LIST,
            payload: res.data
        });
    } catch (error) {
        console.log(error)
    }
}

export const newCustomer = customer => async dispatch => {
    try {
        await api.post('/customers/add', customer);
        dispatch(getCustomersList())
    } catch (error) {
        // dispatch({
        //     type: PRODUCT_ERROR
        // })
    }
}

export const updateCustomer = customer => async dispatch => {
    try {
        await api.post('/customers/update', customer)
        dispatch(getCustomersList())
    } catch (error) {
        // dispatch({
        //     type: PRODUCT_ERROR
        // })
    }
}

export const deleteCustomer = customerId => async dispatch => {
    try {
        await api.post('/customers/delete', {customerId: customerId})
        dispatch(getCustomersList())
    } catch (error) {
        // dispatch({
        //     type: PRODUCT_ERROR
        // })
    }
}

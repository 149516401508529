import React from "react";
import { connect } from 'react-redux';
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

class CardsHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        {this.props.parentName}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                {/* <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral"
                    color="default"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="sm"
                  >
                    New
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="sm"
                  >
                    Filters
                  </Button>
                </Col> */}
              </Row>

              <Row>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Liczba Faktur
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.monthInvoices}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">Ten miesiąc</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Zysk netto
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {(Math.round(( this.props.monthNettoPrice ) * 100) / 100).toFixed(2).toString()} zł
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">Ten miesiąc</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Zysk brutto
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {(Math.round(( this.props.monthPrice ) * 100) / 100).toFixed(2).toString()} zł
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">Ten miesiąc</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Liczba Faktur
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.props.invoicesList.length}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">W tym roku</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Zysk netto
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {(Math.round(( this.props.fullNettoPrice ) * 100) / 100).toFixed(2).toString()} zł
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">Cały okres</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Zysk brutto
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {(Math.round(( this.props.fullPrice ) * 100) / 100).toFixed(2).toString()} zł
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap">Cały okres</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  invoicesList: state.invoices.invoicesList
})

export default connect(
  mapStateToProps,
  { }
)(CardsHeader);

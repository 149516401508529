const columns = [
    {
        dataField: "lp",
        text: "lp.",
        sort: false
    },
    {
        dataField: "service",
        text: "Nazwa usługi",
        sort: false
    },
    {
        dataField: "jm",
        text: "jm.",
        sort: false
    },
    {
        dataField: "count",
        text: "ilość",
        sort: false
    },
    {
        dataField: "nettoPerUnit",
        text: "cena jedn. netto",
        sort: false
    },
    {
        dataField: "netto",
        text: "wartość netto",
        sort: false
    },
    {
        dataField: "vat",
        text: "VAT",
        sort: false
    },
    {
        dataField: "brutto",
        text: "wartość brutto",
        sort: false
    },
]

export { columns };
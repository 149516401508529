import React from 'react';
import Select from 'react-select';
import { Col, Input, Row, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const options = [
    { value: 'cash', label: 'Gotówka' },
    { value: 'transfer', label: 'Przelew' },
  ];
   
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided) => ({
        ...provided,
        fontSize: "0.875rem",
        transition: "all 0.15s ease-in-out",
        height:" calc(1.5em + 1.25rem + 5px)",
        fontWeight: 400,
        lineHeight: "1.5",
        color: "#8898aa",
        backgroundColor: "#fff",
        backgroundClip: "padding-box",
        border: "1px solid #dee2e6",
        borderRadius: "0.25rem",
        boxShadow: "0 3px 2px rgba(233, 236, 239, 0.05)",
    }),
  }

class InvoicePayment extends React.Component{
    state = {
        selectedOption: null,
        paymentDay: null
    };

    parseDate = () => {
        let d = this.props.executionDate.split('-')
        return new Date(`${d[2]}-${d[1]}-${d[0]}`)
    }

    handleChange = selectedOption => {
        this.setState({ 
            selectedOption,
            paymentDays: 0,
            paymentDay: this.parseDate()
        });
        this.props.changeInvoiceValue('payment', selectedOption.value)
        this.props.changeInvoiceValue('paymentDays', 0)
        this.props.changeInvoiceValue('paymentDay', this.parseDate())
    };

    setPaymentDay = days =>{
        let ed = this.parseDate();
        ed.setDate(ed.getDate() + days);
        this.setState({
            paymentDay: ed
        })
        this.props.changeInvoiceValue('paymentDays', days)
        this.props.changeInvoiceValue('paymentDay', ed)
    }

    render(){
        const { selectedOption } = this.state;
        return(
            <Row>
                <Col md={6}>
                    <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        styles={customStyles}
                    />
                </Col>
                {this.state.selectedOption && this.state.selectedOption.value === 'transfer' ? 
                    <Col md={12} style={{marginTop: '1.5rem'}}>
                        <Row>
                            <Col md={6}>
                                <label className="form-control-label">
                                    Termin płatności
                                </label>
                                <InputGroup>
                                    <Input
                                        placeholder="Ilość dni"
                                        type="number"
                                        value={this.state.paymentDays}
                                        onChange={e=>{
                                            let days = e.target.value;
                                            this.setState({paymentDays: days})
                                            this.setPaymentDay(Number(days))
                                        }}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <small className="font-weight-bold">
                                                DNI
                                            </small>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <label className="form-control-label">
                                    Data płatności
                                </label>
                                <Input 
                                    type="text"
                                    value={(this.state.paymentDay).toISOString().split('T')[0]}
                                    disabled />
                            </Col>
                        </Row>
                    </Col>
                :''}
            </Row>
        )
    }
}

export default InvoicePayment;
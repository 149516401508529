import React from 'react';
import { Row, Col } from 'reactstrap';

const units = ['', 'jeden', 'dwa', 'trzy', 'cztery', 'pięć', 'szceść', 'siedem', 'osiem', 'dziwieć', 'dziesięć', 'jedenaście', 'dwanaście', 'trzynaście', 'czternaście', 'piętnaście', 'szesnaście', 'siedemnaście', 'osiemnaście', 'dziewiętnaście'];
const dozens = ['','','dwadzieścia', 'trzydzieści', 'czterdzieści', 'pięćdziesiąt', 'sześćdziesiąt', 'siedemdziesiąt', 'osiemdziesiąt', 'dziwiećdziesiąt'];
const hundreds = ['','sto','dwieście', 'trzysta', 'czterysta', 'pięćset', 'sześćset', 'siedemset', 'osiemset', 'dziewięćset', 'tysiąc'];

class InvoiceCounter extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
        };
      }

    counter(type){
        let total = 0;
        this.props.products.forEach(product => {
            if(product[type]!== 0){
                let v = product[type].split("zł");
                total += parseFloat(v[0]);
            }
        });
        return (Math.round((total ) * 100) / 100).toFixed(2).toString();
    }

    getVat(){
        let vatNumber = 0;
        this.props.products.forEach(product => {
            if(product['vat'] > vatNumber){
                vatNumber = product['vat']
            }
        });
        return vatNumber; 
    }

    numerToString(total, currency='złotych'){
        let strTotal = total.toString();
        let arrTotal = strTotal.split('');
        if(total < 20)
            return units[total] + ` ${currency}`;
        else{
            if(arrTotal.length === 2) return dozens[arrTotal[0]] + ' ' + units[arrTotal[1]] + ` ${currency}`;
            if(arrTotal.length === 3) return hundreds[arrTotal[0]] + ' ' + dozens[arrTotal[1]] + ' ' + units[arrTotal[2]] + ` ${currency}`;
            if(arrTotal.length === 4){
                let firstPart = '';
                if(parseInt(arrTotal[0]) === 1)
                    firstPart = 'jeden tysiąc ';
                else if(parseInt(arrTotal[0]) < 5)
                    firstPart = units[arrTotal[0]] + ' tysiące ';
                else
                    firstPart = units[arrTotal[0]] + ' tysięcy ';

                return firstPart + hundreds[arrTotal[1]] + ' ' + dozens[arrTotal[2]] + ' ' + units[arrTotal[3]] + ` ${currency}`;
            } 
        }
    }

    toWord(){
        let total = 0;
        this.props.products.forEach(product => {
            if(product['brutto']!== 0){
                let v = product['brutto'].split("zł");
                total += parseFloat(v[0]);
            }
        });
        let totalFloat = (Math.round((Number(total)) * 100) / 100).toFixed(2)
        let cents = totalFloat.substring(totalFloat.indexOf(".")+1, totalFloat .length)
        if(Number(cents) > 0){
            let money = parseInt(total)
            let moneyString = this.numerToString(money);
            let centsString = this.numerToString(parseInt(cents), `${parseInt(cents) > 4 ? 'groszy':'grosze'}`);
            return `${moneyString} i ${centsString}`
        }else
            return this.numerToString(total);
    }

    renderTabs(){
        let arr = [];
        this.props.products.forEach(product => {
            let index = arr.findIndex(e=>e.vat===product['vat'])
            if(index >= 0){
                arr[index].netto = Number(arr[index].netto) + Number(product['netto'])
                arr[index].brutto = Number(arr[index].brutto) + Number(product['brutto'])
            }else{
                arr.push({
                    netto: product['netto'],
                    vat: product['vat'] ,
                    brutto: product['brutto'] 
                })
            }
        });
        return arr;
    }

    render(){
        return(
            <>
                <Row>
                    <Col xs="5">
                        <div>
                            Do zapłaty: 
                            {' '+ (Math.round((Number(this.counter('brutto'))) * 100) / 100).toFixed(2).toString()+' zł'}
                        </div> 
                        <div>
                            Słownie: {this.toWord()}
                        </div> 
                    </Col>
                    <Col xs="2"></Col>
                    <Col xs="5">
                        <Row style={{marginRight: '0'}}>
                            {/* 
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0'}}>
                                Razem netto
                            </Col>
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0'}}>
                                VAT
                            </Col>
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0'}}>
                                Razem brutto
                            </Col>
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px'}}>
                                {' '+this.counter('netto')+' zł'}
                            </Col>
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px'}}>
                            {' '+this.getVat()+' %'}
                            </Col>
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px'}}>
                                {' '+this.counter('brutto')+' zł'}
                            </Col> 
                            */}
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0'}}>
                                Razem netto
                            </Col>
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0'}}>
                                VAT
                            </Col>
                            <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0'}}>
                                Razem brutto
                            </Col>
                            {this.renderTabs().map((el, index) => {
                                return <>
                                    <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px', borderBottom: this.renderTabs().length-1 === index ? '1px solid black':0}}>
                                        {`${el.netto} zł`}
                                    </Col>
                                    <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px', borderBottom: this.renderTabs().length-1 === index ? '1px solid black':0}}>
                                        {`${el.vat} %`}
                                    </Col>
                                    <Col xs="4" style={{border: '1px solid black', padding: '10px', fontSize: '16px', borderBottom: this.renderTabs().length-1 === index ? '1px solid black':0}}>
                                        {`${el.brutto} zł`}
                                    </Col> 
                                </>
                            })}
                            {this.renderTabs().length > 1 ? <>
                                <Col xs="6" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0', marginTop: '1rem'}}>
                                    Razem netto
                                </Col>
                                <Col xs="6" style={{border: '1px solid black', padding: '10px', fontSize: '16px' ,borderBottom: '0', marginTop: '1rem'}}>
                                    Razem brutto
                                </Col>
                                <Col xs="6" style={{border: '1px solid black', padding: '10px', fontSize: '16px'}}>
                                    {' '+this.counter('netto')+' zł'}
                                </Col>
                                <Col xs="6" style={{border: '1px solid black', padding: '10px', fontSize: '16px'}}>
                                    {' '+this.counter('brutto')+' zł'}
                                </Col> 
                            </> 
                            : <></>}
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default InvoiceCounter;
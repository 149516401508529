import api from '../utils/api';
import {
    COMPANY_CREATE,
    COMPANY_UPDATE,
    COMPANY_MY,
    COMPANY_EROR
} from './types';
import { loadUser } from './auth';

export const createCompany = data => async dispatch => {
    try {
        const res = await api.post("/company/create", data)
        dispatch(getMyCompany())
        dispatch(loadUser())
        // dispatch({
        //     type: COMPANY_CREATE,
        //     payload: res.data
        // })
    } catch (error) {
        dispatch({
            type: COMPANY_EROR
        })
    }
}

export const updateCompany = data => async dispatch => {
    try {
        const res = await api.post("/company/update", data)
        dispatch(getMyCompany())
        dispatch(loadUser())
        // dispatch({
        //     type: COMPANY_UPDATE,
        //     payload: res.data
        // })
    } catch (error) {
        dispatch({
            type: COMPANY_EROR
        })
    }
}

export const getMyCompany = () => async dispatch => {
    try {
        const res = await api.get("/company/my")
        dispatch({
            type: COMPANY_MY,
            payload: res.data
        })
    } catch (error) {
        dispatch({
            type: COMPANY_EROR
        })
    }
}
import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col } from 'reactstrap';
import {columns} from './form/static';
import './header.css';
import InvoiceCounter from './counter';

class PrintPreview extends React.Component{
    state={products:[], reRender: false}

    componentWillReceiveProps(nextProps){
        this.setState({
            products: nextProps.invoiceValues.products,
            reRender: true
        },() => {
            this.setState({reRender: false})
        })
    }

    parseDate(date){
        let parts = date.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    render(){
        return(
        <>
            <Row id="printPreview" style={{margin: '15px', padding: '15px', fontWeight: '800', fontSize: '20px', color: '#000'}}>
                <Col xs="12">
                    {
                        this.props.invoiceValues.myCompany.logo ? 
                            <img 
                                src={this.props.invoiceValues.myCompany.logo}
                                style={{width: '250px'}} />
                        : ''
                    }
                </Col>
                    <Col xs="12" style={{marginTop: '2rem', paddingBottom: '1.5rem', borderBottom: '1px solid lightgrey'}}>
                        <Row>
                            <Col xs="6">
                                <span style={{textTransform: 'capitalize'}}>{this.props.invoiceValues.docType}</span> nr {this.props.invoiceValues.header.invoiceNumber ? this.props.invoiceValues.header.invoiceNumber : ''}
                            </Col>
                            <Col xs="6" style={{textAlign: 'right'}}>
                                <div>{this.props.invoiceValues.header.invoiceLocation ? this.props.invoiceValues.header.invoiceLocation : ''}</div>
                                <div>Data wystawienia: {this.props.invoiceValues.header.creationDate ? this.props.invoiceValues.header.creationDate : ''}</div>
                                <div>Data wykonania: {this.props.invoiceValues.header.executionDate ? this.props.invoiceValues.header.executionDate : ''}</div>
                            </Col>
                        </Row>
                    </Col>
                <Col xs="12" style={{marginTop: '2rem'}}>
                    <Row>
                        <Col xs="2">
                                Sprzedawca
                        </Col>
                        {
                            this.props.invoiceValues.seller ? 
                            <Col xs="10">
                                <div style={{marginBottom: ''}}>{this.props.invoiceValues.seller.companyName ? this.props.invoiceValues.seller.companyName : ''}</div>
                                <div style={{marginBottom: ''}}>{this.props.invoiceValues.seller.nip ? `NIP: ${this.props.invoiceValues.seller.nip}` : ''}</div>
                                <div style={{marginBottom: ''}}>{this.props.invoiceValues.seller.address ? this.props.invoiceValues.seller.address : ''}</div>
                                <div style={{marginBottom: ''}}>
                                    {this.props.invoiceValues.seller.postalCode ? this.props.invoiceValues.seller.postalCode : ''}
                                    {this.props.invoiceValues.seller.city ? ' '+this.props.invoiceValues.seller.city : ''}
                                </div>
                                <div style={{marginBottom: ''}}>{this.props.invoiceValues.seller.bank ? this.props.invoiceValues.seller.bank : ''} {this.props.invoiceValues.seller.bankAccount ? ' '+this.props.invoiceValues.seller.bankAccount : ''}</div>
                            </Col>
                            :<Col xs="10"></Col>
                        }
                    </Row>
                </Col>
                <Col xs="12" style={{marginTop: '2rem'}}>
                    <Row>
                        <Col xs="2">
                                Nabywca 
                        </Col>
                        {
                            this.props.invoiceValues.buyer ? 
                            <Col xs="10">
                                <div style={{marginBottom: ''}}>
                                    {this.props.invoiceValues.buyer.firstName ? this.props.invoiceValues.buyer.firstName : ''}
                                    {this.props.invoiceValues.buyer.lastName ? ' '+this.props.invoiceValues.buyer.lastName : ''}
                                    {this.props.invoiceValues.buyer.companyName ? this.props.invoiceValues.buyer.companyName : ''}
                                </div>
                                <div style={{marginBottom: ''}}>{this.props.invoiceValues.buyer.pesel ? 'PESEL: ' + this.props.invoiceValues.buyer.pesel : ''}</div>
                                <div style={{marginBottom: ''}}>{this.props.invoiceValues.buyer.nip ? 'NIP: ' + this.props.invoiceValues.buyer.nip : ''}</div>
                                <div style={{marginBottom: ''}}>{this.props.invoiceValues.buyer.address ? this.props.invoiceValues.buyer.address : ''}</div>
                                <div>
                                    {this.props.invoiceValues.buyer.postalCode ? this.props.invoiceValues.buyer.postalCode : ''}
                                    {this.props.invoiceValues.buyer.city ? ' '+this.props.invoiceValues.buyer.city : ''}
                                </div>
                            </Col>
                            :<Col xs="10"></Col>
                        }
                    </Row>
                </Col>
                <Col xs="12" style={{marginTop: '2rem'}}>
                    <Row>
                        <Col xs="2">
                            Płatność
                        </Col>
                        {
                            this.props.invoiceValues.payment ? 
                            <Col xs="10">
                                <div>
                                    {this.props.invoiceValues.payment === 'cash' ? 'Gotówką' : ''}
                                    {this.props.invoiceValues.payment === 'transfer' ? 'Przelew' : ''}
                                </div>
                            </Col>
                            :<Col xs="10"></Col>
                        }
                    </Row>
                </Col>
                {
                    this.props.invoiceValues.payment === 'transfer' ? 
                    <Col xs="12" style={{marginTop: '2rem'}}>
                        <Row>
                            <Col xs="2">
                                Termin
                            </Col>
                            <Col xs="10">
                                {this.parseDate(this.props.invoiceValues.paymentDay.toISOString().split('T')[0])}
                                &nbsp;
                                ({this.props.invoiceValues.paymentDays} dni)
                            </Col>
                        </Row>
                    </Col>
                    :''
                }
                <Col xs="12" style={{marginTop: '2rem'}}>
                    {
                        this.state.reRender ? '' : 
                        <BootstrapTable
                        data={this.state.products}
                        keyField="lp"
                        columns={columns}
                        bootstrap4={true}
                        bordered={true}
                        rowStyle={{border: '1px solid black', fontSize: '18px'}}
                    />
                    }
               </Col>
               <Col xs="12" style={{marginTop: '2rem'}}>
                    <InvoiceCounter products={this.state.products} />
               </Col>
               <Col xs="12" style={{marginTop: '8.5rem'}}>
                   <Row>
                       <Col xs="4">
                           <div style={{width: '100%', borderBottom: '1px solid black'}}></div>
                           <div style={{width: '100%', textAlign: 'center', fontSize: '14px'}}>osoba upoważniona do przyjmowania</div>
                       </Col>
                       <Col xs="4"></Col>
                       <Col xs="4">
                            <div style={{width: '100%', borderBottom: '1px solid black'}}></div>
                           <div style={{width: '100%', textAlign: 'center', fontSize: '14px'}}>osoba upoważniona do wystawiania</div>
                       </Col>
                   </Row>
               </Col>
            </Row>
        </>
        )
    }
}

export default PrintPreview;
import React from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Input, Modal, Button, CardHeader, Form, InputGroup, InputGroupAddon, InputGroupText, ListGroup, ListGroupItem } from "reactstrap";

const Adres = ({ handleChange, defaultData }) => {
  let address = defaultData ? defaultData.address : "";
  let postalCode = defaultData ? defaultData.postalCode : "";
  let city = defaultData ? defaultData.city : "";
  return (
    <>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Adres</label>
          <Input
            name="address"
            placeholder="Adres"
            type="text"
            defaultValue={address}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Kod pocztowy</label>
          <Input
            name="postalCode"
            placeholder="Kod pocztowy"
            type="text"
            defaultValue={postalCode}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Miasto</label>
          <Input
            name="city"
            placeholder="Miasto"
            type="text"
            defaultValue={city}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
    </>
  );
};

const Bank = ({ handleChange, defaultData }) => {
  let bank = defaultData ? defaultData.bank : "";
  let bankAccount = defaultData ? defaultData.bankAccount : "";
  return (
    <>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Nazwa banku</label>
          <Input
            name="bank"
            placeholder="Nazwa banku"
            type="text"
            defaultValue={bank}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Numer konta</label>
          <Input
            name="bankAccount"
            placeholder="Numer konta"
            type="text"
            defaultValue={bankAccount}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
    </>
  );
};

const Person = ({ handleChange, defaultData }) => {
  let firstName = defaultData ? defaultData.firstName : "";
  let lastName = defaultData ? defaultData.lastName : "";
  let pesel = defaultData ? defaultData.pesel : "";
  return (
    <>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Imię</label>
          <Input
            name="firstName"
            placeholder="Imię"
            type="text"
            defaultValue={firstName}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Nazwisko</label>
          <Input
            name="lastName"
            placeholder="Nazwisko"
            type="text"
            defaultValue={lastName}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Pesel</label>
          <Input
            name="pesel"
            placeholder="Pesel"
            type="text"
            defaultValue={pesel}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
    </>
  );
};

const Company = ({ handleChange, defaultData }) => {
  let companyName = defaultData ? defaultData.companyName : "";
  let nip = defaultData ? defaultData.nip : "";
  return (
    <>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">Nazwa firmy</label>
          <Input
            name="companyName"
            placeholder="Nazwa firmy"
            type="text"
            defaultValue={companyName}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label">NIP</label>
          <Input
            name="nip"
            placeholder="NIP"
            type="text"
            defaultValue={nip}
            onChange={(event) => handleChange(event)}
          />
        </FormGroup>
      </Col>
    </>
  );
};

class PartiesOfInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "company",
      defaultModal: false,
      listOfCustomers: [],
      filtrPerson: false,
      filtrCompany: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.nameState !== "seller") {
      this.setState({
        type: "person",
      });
    }else{
      this.setState(this.props.myCompany, () => {
        this.props.changeInvoiceValue(this.props.nameState, this.state);
      });
    }
  }

  filterCustomers(type){
    // let arr = this.props.customersList.filter(e => e.customerType === type)
    this.setState({listOfCustomers: this.props.customersList})
  }

  toggleModal = state => {
    if(!this.state.defaultModal){
      this.filterCustomers('person')
    }
    this.setState({
      [state]: !this.state[state]
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.nameState === "seller") {
      this.setState(nextProps.myCompany, () => {
        this.props.changeInvoiceValue(this.props.nameState, this.state);
      });
    }
  }

  handleChange(e) {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.changeInvoiceValue(this.props.nameState, this.state);
      }
    );
  }

  selectCustomer(customer){
    let newState = {}
    if(customer.customerType === 'person')
      newState = { type: customer.customerType, firstName: customer.firstName, lastName: customer.lastName, pesel: customer.pesel};
    else
      newState = { type: customer.customerType, companyName: customer.companyName, nip: customer.nip};
    newState = {...newState, ...{address: customer.address, postalCode: customer.postalCode, city: customer.city}}
    this.setState(newState, () => {
      this.toggleModal("defaultModal")
      this.props.changeInvoiceValue(this.props.nameState, this.state);
    })
  }

  render() {
    return (
      <Row>
        {this.props.nameState === "seller" ? (
          ""
        ) : (
          <>
        <Col md={4}>
        <Button
          block
          className="mb-3"
          color="primary"
          onClick={() => {
            this.toggleModal("defaultModal");
          }}
        >
          Wybierz kontrahenta
        </Button>
        </Col>
        <Col md={12}>
          <hr style={{marginBottom:'2rem', marginTop: '.5rem'}} />
        </Col>
          <Col
            md={12}
            style={{ display: "flex", gap: "1rem", paddingBottom: "1rem" }}
          >
            <div className="custom-control custom-radio mb-3">
              <input
                className="custom-control-input"
                id="customRadio5"
                name="custom-radio-1"
                type="radio"
                checked={this.state.type === "person"}
                onChange={() => {
                  let newState = { type: "person", companyName: undefined, nip: undefined };
                  this.setState(newState)
                  this.props.changeInvoiceValue('buyer', this.state)
                }}
              />
              <label className="custom-control-label" htmlFor="customRadio5">
                Osoba fizyczna
              </label>
            </div>
            <div className="custom-control custom-radio mb-3">
              <input
                className="custom-control-input"
                id="customRadio6"
                name="custom-radio-1"
                type="radio"
                checked={this.state.type === "company"}
                onChange={() => {
                  let newState = { type: "company", firstName: undefined, lastName: undefined, pesel: undefined};
                  this.setState(newState)
                  this.props.changeInvoiceValue('buyer', this.state)
                }}
              />
              <label className="custom-control-label" htmlFor="customRadio6">
                Firma
              </label>
            </div>
          </Col>
          </>
        )}
        <Col md={12}>
          <Row>
            {this.state.type === "company" ? (
              <Company
                handleChange={this.handleChange}
                defaultData={this.state}
              />
            ) : (
              ""
            )}
            {this.state.type === "person" ? (
              <Person
                handleChange={this.handleChange}
                defaultData={this.state}
              />
            ) : (
              ""
            )}
            <Adres handleChange={this.handleChange} defaultData={this.state} />
            {this.props.bank ? (
              <Bank handleChange={this.handleChange} defaultData={this.state} />
            ) : (
              ""
            )}
          </Row>
        </Col>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.defaultModal}
          toggle={() => this.toggleModal("defaultModal")}
        >
          <div className="modal-header" style={{alignItems:'center',borderBottom:'1px solid rgba(0, 0, 0, 0.05)'}}>
            <h6 className="modal-title" id="modal-title-default">
              Wybierz kontrahenta
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{paddingTop: '.5rem'}}>
              <Row>
                {this.state.listOfCustomers.length === 0 ? '' :
                <Col xs={12} className="mb-4">
                  <CardHeader className="py-0">
                    <Form>
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-lg input-group-flush">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <span className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Szukaj" type="search" />
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </CardHeader>
                </Col>}
                <Col xs={2} style={{fontSize:'80%',fontWeight:700}}>
                  Filtry
                </Col>
                <Col xs={5} style={{justifyContent: 'center', display: 'flex'}}>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="checkboxPerson"
                      type="checkbox"
                      checked={this.state.filtrPerson}
                      onChange={()=>this.setState({filtrPerson: !this.state.filtrPerson})}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkboxPerson"
                    >
                      Osoba fizyczna
                    </label>
                  </div>
                </Col>
                <Col xs={5}>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="checkboxCompany"
                      type="checkbox"
                      checked={this.state.filtrCompany}
                      onChange={()=>this.setState({filtrCompany: !this.state.filtrCompany})}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkboxCompany"
                    >
                      Firma
                    </label>
                  </div>
                </Col>
                <Col xs={12} >
                  <hr style={{marginBottom: '1rem',marginTop: 0}} />
                </Col>
                <Col xs={12} >
                <ListGroup className="list my--3" style={{maxHeight:'250px', overflow: 'auto'}} flush>
                  {
                    this.state.listOfCustomers.map(customer => <ListGroupItem key={customer._id} className="px-0">
                      <Row className="align-items-center" style={{margin: 0}}>
                        <Col className="col-auto">
                        </Col>
                        {
                          customer.customerType === 'person' ? <div className="col ml--2">
                            <h4 className="mb-0">
                              {customer.firstName} {customer.lastName}
                            </h4>
                            <small>Pesel {customer.pesel}</small>
                            <br />
                            <small>{customer.address}</small>
                            <br />
                            <small>{customer.postalCode} {customer.city}</small>
                          </div> :
                          <div className="col ml--2">
                            <h4 className="mb-0">
                                {customer.companyName}
                            </h4>
                            <small>NIP {customer.nip}</small>
                            <br />
                            <small>{customer.address}</small>
                            <br />
                            <small>{customer.postalCode} {customer.city}</small>
                          </div>
                        }
                        <Col className="col-auto">
                          <Button color="primary" size="sm" type="button" onClick={()=>this.selectCustomer(customer)}>
                            Wybierz
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>)
                  }
                  </ListGroup>
                </Col>
              </Row>
          </div>
          <div className="modal-footer">
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("defaultModal")}
            >
              Zamknij
            </Button>
          </div>
        </Modal>
      </Row>
    );
  }
}

const mapStatoToProps = (state) => ({
  myCompany: state.companies.myCompany,
  customersList: state.customers.customersList
});

export default connect(mapStatoToProps, {})(PartiesOfInvoice);

import { combineReducers } from 'redux';
import auth from './auth';
import invoices from './invoices';
import companies from './companies';
import customers from './customers';
import dashboard from './dashboard';
import products from './products';

export default combineReducers({
    auth,
    invoices,
    companies,
    customers,
    dashboard,
    products
});
import api from '../utils/api';
import {
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    USER_LOADED,
    AUTH_ERROR,
    COMPANY_MY
} from './types';

// Load User
export const loadUser = () => async dispatch => {
    try {
        const res = await api.get('/auth');
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
        dispatch({
            type: COMPANY_MY,
            payload: res.data.companyData
        })
    } catch (err) {
        dispatch({
            type: AUTH_ERROR
        });
    }
};

// Register User
// export const register = (sendData) => async dispatch => {
//     const config = {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     };
//     const body = JSON.stringify(sendData);
//     try {
//         const res = await api.post('/users/register', body, config);
//         dispatch(setNotification({
//             status: res.status,
//             data: res.data.msg
//         }))
//     } catch (err) {
//         const msg = err.response.data.msg;
//         dispatch(setNotification({
//             status: err.response.status,
//             data: msg
//         }))
//     }
// };
  
// Login User
export const login = ({email, password}) => async dispatch => {
    const body = { email, password };
    try {
        const res = await api.post('/auth', body);
        dispatch({
            type: LOGIN,
            payload: res.data
        });
        dispatch(loadUser());
    } catch (err) {
        const msg = err.response.data;
        dispatch({
            type: LOGIN_FAIL,
            payload: msg
        });
    }
};

export const clearFail = () => dispatch => {
    dispatch({
        type: LOGIN_FAIL,
        payload: null
    });
}
  
// Logout / Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
};
  
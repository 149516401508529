import React from 'react';
import Select from 'react-select';
import ReactDatetime from "react-datetime";
import momentDate from 'moment';
import { Row, Col, Input, FormGroup } from 'reactstrap';
require('moment/locale/pl');

const options = [
    { value: 'rachunek', label: 'Rachunek' },
    { value: 'faktura', label: 'Faktura' },
];
   
const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided) => ({
        ...provided,
        fontSize: "0.875rem",
        transition: "all 0.15s ease-in-out",
        height:" calc(1.5em + 1.25rem + 5px)",
        fontWeight: 400,
        lineHeight: "1.5",
        color: "#8898aa",
        backgroundColor: "#fff",
        backgroundClip: "padding-box",
        border: "1px solid #dee2e6",
        borderRadius: "0.25rem",
        boxShadow: "0 3px 2px rgba(233, 236, 239, 0.05)",
    }),
}

class InvoiceFormHeader extends React.Component{
    constructor(props){
        super(props);
        this.state = { 
            invoiceNumber: '',
            invoiceLocation: '',
            creationDate: null,
            executionDate: null,
            selectedOption: { value: 'rachunek', label: 'Rachunek' }
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        let now = momentDate();
        this.setState({
            creationDate: now.format('DD-MM-YYYY'),
            executionDate: now.format('DD-MM-YYYY')
        }, () => {
            this.props.changeInvoiceValue('header',this.state)
        })
    }

    componentDidUpdate(){
        if(this.props.myCompany && this.state.invoiceLocation === ''){
            this.setState({invoiceLocation: this.props.myCompany.defaultLocation}, () => {
                this.props.changeInvoiceValue('header',this.state)
            })
        }
        if(this.props.invoiceNumber &&  this.state.invoiceNumber === ''){
            this.setState({invoiceNumber: this.props.invoiceNumber}, () => {
                this.props.changeInvoiceValue('header',this.state)
            })
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        }, () => {
            this.props.changeInvoiceValue('header',this.state)
        });
    }

    changeDoc(e){
        this.setState({
            selectedOption: e
        }, () => {
            this.props.changeInvoiceValue('docType', e.value)
        })
    }

    handleDateTimePicker = (moment, name) => this.setState({
        [name]: moment.format('DD-MM-YYYY')
    }, () => {
        this.props.changeInvoiceValue('header',this.state)
    });

    render(){
        return(
            <>
                <Row>
                    <Col md={12} className="pb-4">
                        <FormGroup>
                            <label className="form-control-label">
                                Rodzaj dokumentu
                            </label>
                            <Select
                                value={this.state.selectedOption}
                                onChange={e => this.changeDoc(e)}
                                options={options}
                                styles={customStyles}
                            />
                        </FormGroup>
                    </Col>
                    <Col md='6' sm='12'>
                        <FormGroup>
                            <label className="form-control-label">
                                Numer faktury
                            </label>
                            <Input
                            name="invoiceNumber"
                            placeholder="Numer faktury"
                            type="text"
                            onChange={this.handleChange}
                            value={this.state.invoiceNumber}
                            // disabled
                            />
                        </FormGroup>
                    </Col>
                    <Col md='6' sm='12'>
                        <FormGroup>
                            <label className="form-control-label">
                                Lokalizacja
                            </label>
                            <Input
                            name="invoiceLocation"
                            placeholder="Lokalizacja"
                            type="text"
                            value={this.state.invoiceLocation}
                            onChange={this.handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">
                                Data wystawienia
                            </label>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Data wystawienia"
                                }}
                                timeFormat={false}
                                defaultValue={new Date()}
                                name="creationDate"
                                onChange={moment => this.handleDateTimePicker(moment, 'creationDate')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">
                                Data wykonania
                            </label>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Data wykonania"
                                }}
                                timeFormat={false}
                                defaultValue={new Date()}
                                name="executionDate"
                                onChange={moment => this.handleDateTimePicker(moment, 'executionDate')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        )
    }
}

export default InvoiceFormHeader;
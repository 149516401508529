import React from "react";
import { connect } from "react-redux";
import { getCustomersList, newCustomer, updateCustomer, deleteCustomer } from "../../../actions/customers";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Table,
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Form,
    Modal,
    CardBody,
    FormGroup,
    InputGroup,
    Input
} from "reactstrap";
import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader.js";

class Customers extends React.Component {
    state = { 
        notificationModal: false,
        formModal: false,
        firstName: '',
        lastName: '',
        companyName: '',
        customerType: 'person',
        nip: '',
        pesel: '',
        address: '',
        postalCode: '',
        city: '',
        selectedCustomer: null
    }

    componentDidMount(){
        this.loadData()
    }

    async loadData(){
        await this.props.getCustomersList();
    }

    toggleModal = state => {
        this.setState({
          [state]: !this.state[state]
        });
    };

    save = e => {
        e.preventDefault();
        let dataToSave = {
            customerType: this.state.customerType,
            address: this.state.address,
            postalCode: this.state.postalCode,
            city: this.state.city,
        }

        if(this.state.customerType === 'person'){
            dataToSave.firstName = this.state.firstName
            dataToSave.lastName = this.state.lastName
            dataToSave.pesel = this.state.pesel
        }else{
            dataToSave.companyName = this.state.companyName
            dataToSave.nip = this.state.nip
        }

        if(this.state.selectedCustomer){
            dataToSave._id = this.state.selectedCustomer
            this.props.updateCustomer(dataToSave)
            this.toggleModal("formModal")
        }else{
            this.props.newCustomer(dataToSave)
            this.toggleModal("formModal")
        }
    }

    render() {
    return (
      <>
        <SimpleHeader name="Kontrahenci" parentName="Kontrahenci" />
        <Container className="mt--6" fluid>
            <Row>
                <div className="col">
                    <Card>
                        <CardHeader className="border-0">
                            <Row>
                                <Col xs="6">
                                    <h3 className="mb-0">Kontrahenci</h3>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <Button
                                        className="btn-round btn-icon"
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                firstName: '',
                                                lastName: '',
                                                companyName: '',
                                                customerType: 'person',
                                                nip: '',
                                                pesel: '',
                                                address: '',
                                                postalCode: '',
                                                city: '',
                                                selectedCustomer: null
                                            },()=>{this.toggleModal("formModal")})
                                        }}
                                        size="sm"
                                    >
                                        <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-plus" />
                                        </span>
                                        <span className="btn-inner--text">Dodaj Kontrahenta</span>
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th className="sort" data-sort="name" scope="col">
                                        Nazwa
                                    </th>
                                    <th className="sort" data-sort="name" scope="col">
                                        Typ
                                    </th>
                                    <th className="sort" data-sort="budget" scope="col">
                                        NIP | PESEL
                                    </th>
                                    <th className="sort" data-sort="status" scope="col">
                                        Adres
                                    </th>
                                    <th className="sort" data-sort="status" scope="col">
                                        Kod pocztowy
                                    </th>
                                    <th className="sort" data-sort="status" scope="col">
                                        Miasto
                                    </th>
                                    {/* <th className="sort" data-sort="status" scope="col">
                                        Data dodania
                                    </th> */}
                                    <th scope="col" />
                                </tr>
                            </thead>
                            <tbody className="list">
                                {
                                    this.props.customersList.map(customer => {
                                    return(<tr key={customer._id}>
                                        <th scope="row" >
                                            {customer.customerType==='person' ? `${customer.firstName} ${customer.lastName}` : customer.companyName}
                                        </th>
                                        <td>
                                            {customer.customerType==='person' ? 'Osoba' : 'Firma'}
                                        </td>
                                        <td>
                                            {customer.customerType==='person' ? customer.pesel : customer.nip}
                                        </td>
                                        <td>
                                            {customer.address}
                                        </td>
                                        <td>
                                            {customer.postalCode}
                                        </td>
                                        <td>
                                            {customer.city}
                                        </td>
                                        {/* <td>
                                            {new Date(customer.createdAt).toLocaleString().replace(/\s\d{2,4}:\d{2}:\d{2}$/, '').replace(/,\s*$/, "")}
                                        </td> */}
                                        <td className="text-right">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                className="btn-icon-only text-light"
                                                color=""
                                                role="button"
                                                size="sm"
                                                >
                                                    <i className="fas fa-ellipsis-v" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                    <DropdownItem
                                                        onClick={()=>alert('w trakcie przygotwania')}
                                                    >
                                                        Faktury
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            let data = {
                                                                selectedCustomer: customer._id,
                                                                customerType: customer.customerType,
                                                                address: customer.address,
                                                                postalCode: customer.postalCode,
                                                                city: customer.city
                                                            }
                                                            if(customer.customerType === 'person'){
                                                                data.firstName = customer.firstName
                                                                data.lastName = customer.lastName
                                                                data.pesel = customer.pesel
                                                            }else{
                                                                data.companyName = customer.companyName
                                                                data.nip = customer.nip
                                                            }
                                                            this.setState(data,()=>{
                                                                this.toggleModal('formModal')
                                                            })
                                                        }}
                                                    >
                                                        Edytuj
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={()=> {
                                                            this.setState({
                                                                selectedCustomer: customer._id,
                                                            }, () => this.toggleModal("notificationModal"))
                                                        }}
                                                    >
                                                        Usuń
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>)
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card>
                </div>
            </Row>
        </Container>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent">
                {this.state.selectedCustomer ? 'Edytuj':'Dodaj'} Kontrahenta
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("formModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form onSubmit={this.save}>
                    <FormGroup>
                        <div className="custom-control custom-radio mb-3">
                            <input
                                className="custom-control-input"
                                id="customRadio5"
                                name="custom-radio-1"
                                type="radio"
                                checked={this.state.customerType === "person"}
                                onChange={() => {
                                  let newState = { customerType: "person", companyName: '', nip: '' };
                                  this.setState(newState)
                                }}
                            />
                            <label className="custom-control-label" htmlFor="customRadio5">
                                Osoba fizyczna
                            </label>
                            </div>
                            <div className="custom-control custom-radio mb-3">
                            <input
                                className="custom-control-input"
                                id="customRadio6"
                                name="custom-radio-1"
                                type="radio"
                                checked={this.state.customerType === "company"}
                                onChange={() => {
                                    let newState = { customerType: "company", firstName: '', lastName: '', pesel: '' };
                                    this.setState(newState)
                                }}
                            />
                            <label className="custom-control-label" htmlFor="customRadio6">
                                Firma
                            </label>
                        </div>
                    </FormGroup>
                    {
                        this.state.customerType === 'person' ? <>
                            <FormGroup
                                className={classnames("mb-3", {
                                    focused: this.state.focusedFirstName
                                })}
                            >
                                <label
                                    className="form-control-label"
                                    htmlFor="example3cols1Input"
                                >
                                    Imię
                                </label>
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <Input
                                        placeholder="Imie"
                                        type="text"
                                        onFocus={() =>
                                            this.setState({ focusedFirstName: true })
                                        }
                                        onBlur={() =>
                                            this.setState({ focusedFirstName: false })
                                        }
                                        value={this.state.firstName}
                                        onChange={e=>this.setState({firstName: e.target.value})}
                                        required
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup
                                className={classnames("mb-3", {
                                    focused: this.state.focusedLastName
                                })}
                            >
                                <label
                                    className="form-control-label"
                                    htmlFor="example3cols1Input"
                                >
                                    Nazwisko
                                </label>
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <Input
                                        placeholder="Nazwisko"
                                        type="text"
                                        onFocus={() =>
                                            this.setState({ focusedLastName: true })
                                        }
                                        onBlur={() =>
                                            this.setState({ focusedLastName: false })
                                        }
                                        value={this.state.lastName}
                                        onChange={e=>this.setState({lastName: e.target.value})}
                                        required
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup
                                className={classnames("mb-3", {
                                    focused: this.state.focusedpesel
                                })}
                            >
                                <label
                                    className="form-control-label"
                                    htmlFor="example3cols1Input"
                                >
                                    Pesel
                                </label>
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <Input
                                        placeholder="Pesel"
                                        type="text"
                                        onFocus={() =>
                                            this.setState({ focusedpesel: true })
                                        }
                                        onBlur={() =>
                                            this.setState({ focusedpesel: false })
                                        }
                                        value={this.state.pesel}
                                        onChange={e=>this.setState({pesel: e.target.value})}
                                        required
                                    />
                                </InputGroup>
                            </FormGroup>
                        </> : 
                        <>
                            <FormGroup
                                className={classnames("mb-3", {
                                    focused: this.state.focusedcompanyName
                                })}
                            >
                                <label
                                    className="form-control-label"
                                    htmlFor="example3cols1Input"
                                >
                                    Nazwa firmy
                                </label>
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <Input
                                        placeholder="Nazwa firmy"
                                        type="text"
                                        onFocus={() =>
                                            this.setState({ focusedcompanyName: true })
                                        }
                                        onBlur={() =>
                                            this.setState({ focusedcompanyName: false })
                                        }
                                        value={this.state.companyName}
                                        onChange={e=>this.setState({companyName: e.target.value})}
                                        required
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup
                                className={classnames("mb-3", {
                                    focused: this.state.focusednip
                                })}
                            >
                                <label
                                    className="form-control-label"
                                    htmlFor="example3cols1Input"
                                >
                                    NIP
                                </label>
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <Input
                                        placeholder="NIP"
                                        type="text"
                                        onFocus={() =>
                                            this.setState({ focusednip: true })
                                        }
                                        onBlur={() =>
                                            this.setState({ focusednip: false })
                                        }
                                        value={this.state.nip}
                                        onChange={e=>this.setState({nip: e.target.value})}
                                        required
                                    />
                                </InputGroup>
                            </FormGroup>
                        </>
                    }
                        <FormGroup
                            className={classnames("mb-3", {
                                focused: this.state.focusedaddress
                            })}
                        >
                            <label
                                className="form-control-label"
                                htmlFor="example3cols1Input"
                            >
                                Adres
                            </label>
                            <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                    placeholder="Adres"
                                    type="text"
                                    onFocus={() =>
                                        this.setState({ focusedaddress: true })
                                    }
                                    onBlur={() =>
                                        this.setState({ focusedaddress: false })
                                    }
                                    value={this.state.address}
                                    onChange={e=>this.setState({address: e.target.value})}
                                    required
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup
                            className={classnames("mb-3", {
                                focused: this.state.focusedpostalCode
                            })}
                        >
                            <label
                                className="form-control-label"
                                htmlFor="example3cols1Input"
                            >
                                Kod Pocztowy
                            </label>
                            <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                    placeholder="Kod Pocztowy"
                                    type="text"
                                    onFocus={() =>
                                        this.setState({ focusedpostalCode: true })
                                    }
                                    onBlur={() =>
                                        this.setState({ focusedpostalCode: false })
                                    }
                                    value={this.state.postalCode}
                                    onChange={e=>this.setState({postalCode: e.target.value})}
                                    required
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup
                            className={classnames("mb-3", {
                                focused: this.state.focusedcity
                            })}
                        >
                            <label
                                className="form-control-label"
                                htmlFor="example3cols1Input"
                            >
                                Miasto
                            </label>
                            <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                    placeholder="Miasto"
                                    type="text"
                                    onFocus={() =>
                                        this.setState({ focusedcity: true })
                                    }
                                    onBlur={() =>
                                        this.setState({ focusedcity: false })
                                    }
                                    value={this.state.city}
                                    onChange={e=>this.setState({city: e.target.value})}
                                    required
                                />
                            </InputGroup>
                        </FormGroup>
                    <div className="text-center">
                        <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                        >
                            Zapisz
                        </Button>
                    </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={this.state.notificationModal}
          toggle={() => this.toggleModal("notificationModal")}
        >
          <div className="modal-header">
            <h6
              className="modal-title"
              id="modal-title-notification"
            >
              
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                this.toggleModal("notificationModal")
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">
                Czy napewno chcesz usunąć <b>kontrahenta</b> ? 
              </h4>
              <p>
                
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={()=>{
                this.props.deleteCustomer(this.state.selectedCustomer)
                this.toggleModal("notificationModal")
              }}
            >
              TAK
            </Button>
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                this.toggleModal("notificationModal")
              }
            >
              NIE
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
    customersList: state.customers.customersList,
})

export default connect(
    mapStateToProps,
    { getCustomersList, newCustomer, updateCustomer, deleteCustomer }
)(Customers);

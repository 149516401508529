import React from 'react'
import Loader from 'react-loader-spinner'

export const PageLoader = () => {
    return(
        <div style={{position: "fixed", top: '0px', left: "0px", bottom: "0px", right: "0px", display: "flex", alignItems: "center", overflow: "auto", zIndex: 1000}}>
            <div style={{margin: "auto", maxHeight: "100%"}}>
                <Loader
                    type="Puff"
                    color="#51bcda"
                    height={100}
                    width={100}
                />
            </div>
        </div>
    )
}

export const DataLoader = () => {
    return(
        <div style={{width: '100%', height: '100vh', display: "flex", alignItems: "center", overflow: "auto", zIndex: 1000}}>
            <div style={{margin: "auto", maxHeight: "100%"}}>
                <Loader
                    type="Puff"
                    color="#51bcda"
                    height={100}
                    width={100}
                />
            </div>
        </div>
    )
}

export default PageLoader;
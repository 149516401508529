import {
    CUSTOMERS_LIST
} from '../actions/types';

const initialState = {
    customersList: []
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CUSTOMERS_LIST:
            return{
                ...state,
                customersList: payload
            }
            break;

        default:
            return state;
    }
}
import {
    INVOICE_NEW,
    INVOICE_SAVE,
    INVOICE_LIST,
    INVOICE_EROR,
    INVOICE_EROR_HIDE
} from '../actions/types';

const initialState = {
    invoiceNumber: null,
    invoiceData: [],
    invoicesList: [],
    error: false
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case INVOICE_NEW:
            return{
                ...state,
                error: false,
                invoiceNumber: payload
            }
            break;
    
        case INVOICE_SAVE:
            return{
                ...state,
                error: false
            }

        case INVOICE_LIST:
            return{
                ...state,
                error: false,
                invoicesList: payload
            }

        case INVOICE_EROR:
            return{
                ...state,
                error: true
            }

        case INVOICE_EROR_HIDE:
            return{
                ...state,
                error: false
            }
        default:
            return state;
    }
}
import React from "react";
import { connect } from "react-redux";
import { newInvoice, saveInvoice } from "../../../actions/invoice";
import { getCustomersList } from "../../../actions/customers";
import { getProductsLst } from "../../../actions/product";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ReactToPrint from "react-to-print";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import InvoiceFormHeader from "./form/header";
import PartiesOfInvoice from "./form/partiesOfInvoice";
import InvoiceFormProducts from "./form/products";
import InvoicePayment from "./form/payment";
import PrintPreview from "./printPreview";
import NotificationAlert from "react-notification-alert";
// import Pdf from "react-to-pdf";

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docType: 'rachunek',
      invoiceNumber: null,
      header: {
        executionDate: "",
      },
      seller: null,
      buyer: null,
      payment: null,
      paymentDays: 0,
      paymentDay: null,
      products: [],
      myCompany: null,
    };
    this.changeInvoiceValue = this.changeInvoiceValue.bind(this);
  }

  componentDidMount() {
    this.getNumber();
    this.props.getCustomersList();
    this.props.getProductsLst();
  }

  async getNumber() {
    await this.props.newInvoice();
    this.setState({
      invoiceNumber: this.props.invoiceNumber,
      myCompany: this.props.myCompany,
    });
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.invoiceError !== nextProps.invoiceError){
      if(nextProps.invoiceError)
        this.notify("danger")
    }
  }

  notify = type => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Błąd!
          </span>
          <span data-notify="message">
            Sprawdź czy poprawnie uzupełniłeś wszystkie pola!
            <br />
            W razie problemów skontaktuj się z administratorem.
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  changeInvoiceValue(name, value) {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.invoiceNumber !== null)
          localStorage.setItem(
            "invoice_" + this.state.invoiceNumber,
            JSON.stringify(this.state)
          );
      }
    );
  }

  checkInvoiceNumber() {
    if (this.state.header.invoiceNumber) {
      this.setState({
        ...this.state,
        ...{ invoiceNumber: this.state.header.invoiceNumber },
      });
    }
  }

  async saveInvoice(setUrl=true) {
    if (this.state.header.invoiceNumber) {
      if (this.state.header.invoiceNumber !== this.state.invoiceNumber) {
        this.setState(
          {
            ...this.state,
            ...{ invoiceNumber: this.state.header.invoiceNumber },
          },
          () => {
            this.saveInvoice();
          }
        );
        return;
      }
    }
    let dataToSave = {
        ...this.state,
        ...{
            invoiceLocation: this.state.header.invoiceLocation,
            creationDate: this.parseDate(this.state.header.creationDate),
            executionDate: this.parseDate(this.state.header.executionDate),
        }
    }
    await this.props.saveInvoice(dataToSave)
    if(!this.props.invoiceError & setUrl)
      this.props.history.push("/admin/historia");
  }

  parseDate(date) {
    let parts = date.split("-");
    return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
  }

  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader name="Faktura" defaultLocation={this.state.myCompany} />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3
                      className="mb-0"
                      onClick={() => console.log(this.props.customersList)}
                    >
                      Nowa faktura 
                      {/* ({this.props.invoiceError ? 'Error': 'Ok'}) */}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12" style={{ marginBottom: ".7rem" }}>
                        <InvoiceFormHeader
                          changeInvoiceValue={this.changeInvoiceValue}
                          invoiceNumber={this.state.invoiceNumber}
                          myCompany={this.state.myCompany}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h2>Sprzedawca</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <PartiesOfInvoice
                          bank={true}
                          changeInvoiceValue={this.changeInvoiceValue}
                          nameState="seller"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h2>Nabywca</h2>
                  </CardHeader>
                  <CardBody>
                    <PartiesOfInvoice
                      bank={false}
                      changeInvoiceValue={this.changeInvoiceValue}
                      nameState="buyer"
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h2>Sposób płatności</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <InvoicePayment
                          changeInvoiceValue={this.changeInvoiceValue}
                          executionDate={this.state.header.executionDate}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h2>Produkty/Usługi</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <InvoiceFormProducts
                          changeInvoiceValue={this.changeInvoiceValue}
                        />
                      </Col>
                      <Col md="12" style={{ marginTop: "4rem" }}>
                        <Row>
                          <Col xs="6">
                            {/* <Button color="danger">Anuluj</Button> */}
                          </Col>
                          <Col xs="6" style={{ textAlign: "right" }}>
                            <ReactToPrint
                              onBeforePrint={() => this.saveInvoice(false)}
                              trigger={() => (
                                <Button
                                  color="success"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                   Drukuj
                                </Button>
                              )}
                              content={() => this.componentRef}
                            />
                            {/* <Button
                              color="success"
                              onClick={() => this.getPdf()}
                            >
                              Pobierz PDF
                            </Button> */}
                          {/* <Pdf targetRef={() => this.componentRef} filename="code-example.pdf">
                            {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
                          </Pdf> */}
                            <Button
                              color="success"
                              onClick={() => this.saveInvoice()}
                            >
                              Zapisz i Wyjdź
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h2>Podgląd faktury (live)</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        {this.state.invoiceNumber !== null ? (
                          <PrintPreview
                            ref={(el) => (this.componentRef = el)}
                            invoiceValues={this.state}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStatoToProps = (state) => ({
  invoiceError: state.invoices.error,
  invoiceNumber: state.invoices.invoiceNumber,
  myCompany: state.companies.myCompany,
  customersList: state.customers.customersList,
});

export default connect(mapStatoToProps, {
  newInvoice,
  saveInvoice,
  getCustomersList,
  getProductsLst
})(Invoice);

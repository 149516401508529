import React from 'react';
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Button, Modal, Table } from 'reactstrap';
import {columns} from './static';

class InvoiceFormProducts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            products: [],
            selectedProducts: [],
        }
    }

    toggleModal = state => {
        this.setState({
            selectedProducts: [],
            [state]: !this.state[state]
        });
    };

    addEmpty(){
        let newProduct = {
            lp: this.state.products.length + 1,
            service: '',
            jm: '',
            count: 1,
            nettoPerUnit: 0,
            netto: 0,
            vat: 0,
            brutto: 0
        }
        this.setState({
            products: [...this.state.products, newProduct]
        }, () =>  this.saveChanges())
    }

    addProducts(){
        let newProducts = []
        let productsToAdd = this.props.productsList.filter(e => this.state.selectedProducts.includes(e._id))
        productsToAdd.forEach(e => {
            newProducts.push({
                lp: this.state.products.length + newProducts.length + 1,
                service: e.name,
                jm: e.jm,
                count: 1,
                nettoPerUnit: e.nettoPerUnit ? (Math.round(( e.nettoPerUnit ) * 100) / 100).toFixed(2).toString() : 0,
                netto: e.nettoPerUnit ? (Math.round(( e.nettoPerUnit ) * 100) / 100).toFixed(2).toString() : 0,
                vat: 0,
                brutto: e.nettoPerUnit ? (Math.round(( e.nettoPerUnit ) * 100) / 100).toFixed(2).toString() : 0,
            })
        })
        this.setState({
            products: [...this.state.products, ...newProducts],
            defaultModal: false
        }, () => this.saveChanges())
    }

    removeLast(){
        let newProducts = [...this.state.products]
        newProducts.pop();
        this.setState({
            products: newProducts
        }, () => {
            this.saveChanges()
        })
    }

    updateFiled(oldValue, newValue, row, column) {
        if(column.dataField === "nettoPerUnit" || column.dataField === 'vat' || column.dataField === 'count'){
            let vatNumber = typeof row.vat === 'string' ? row.vat.replace(/[^.\d]/g, '') : row.vat;
            row.netto = (Math.round((Number(row.count) * Number(row.nettoPerUnit)) * 100) / 100).toFixed(2).toString();
            let vatCount = Number(vatNumber) > 0 ? (Number(vatNumber) / 100) * Number(row.netto) : 0;
            row.brutto = (Math.round(( Number(row.netto) + Number(vatCount) ) * 100) / 100).toFixed(2).toString();
            let products = [...this.state.products]
            products[row.lp - 1] = row;
            this.setState({products: [...products]}, () => {
                this.saveChanges()
            })
        }else
           this.saveChanges()
    }

    saveChanges(){
        this.props.changeInvoiceValue('products', this.state.products)
    }

    render(){
        return(
            <>
                <BootstrapTable
                    data={this.state.products}
                    keyField="lp"
                    columns={columns}
                    bootstrap4={true}
                    bordered={true}
                    cellEdit={ cellEditFactory({ 
                        mode: 'dbclick', 
                        blurToSave: true, 
                        afterSaveCell: (oldValue, newValue, row, column) => { this.updateFiled(oldValue, newValue, row, column) }
                    }) }
                />
                <Button color="success" onClick={() => this.toggleModal("defaultModal")}>Dodaj produkt/usługę</Button>
                <Button color="success" onClick={()=>this.addEmpty()}>Dodaj pustą pozycje</Button>
                <Button color="danger" onClick={()=>this.removeLast()}>Usuń ostatnią pozycje</Button>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.defaultModal}
                    toggle={() => this.toggleModal("defaultModal")}
                    >
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">
                            Wybierz produkt/usułgę
                        </h6>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("defaultModal")}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Table className="align-items-center table-flush" hover responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>
                                        <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="table-check-all"
                                            type="checkbox"
                                            checked={this.state.selectedProducts.length > 0 && this.state.selectedProducts.length === this.props.productsList.length}
                                            onChange={(e)=>{
                                                let products = [];
                                                if(e.target.checked){
                                                    this.props.productsList.forEach(el => {
                                                        products.push(el._id)
                                                    });
                                                }
                                                this.setState({selectedProducts: products})
                                            }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="table-check-all"
                                        />
                                        </div>
                                    </th>
                                    <th>Nazwa</th>
                                    <th>Cena Netto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.productsList.map(product => <tr key={product._id}>
                                        <td>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    id={product._id}
                                                    type="checkbox"
                                                    checked={this.state.selectedProducts.includes(product._id)}
                                                    onChange={e=>{
                                                        let products = [...this.state.selectedProducts]
                                                        if(e.target.checked){
                                                            products.push(product._id)
                                                        }else{
                                                            let index = products.findIndex(e => e === product._id)
                                                            products.splice(index, 1)
                                                        }
                                                        this.setState({selectedProducts: products})
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={product._id}
                                                />
                                            </div>
                                        </td>
                                        <td>{product.name}</td>
                                        <td>{product.nettoPerUnit}</td>
                                    </tr>)
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="button" onClick={() => this.addProducts()}>
                            Wybierz
                        </Button>
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("defaultModal")}
                        >
                            Zamknij
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStatoToProps = (state) => ({
    productsList: state.products.productsList,
});
  
export default connect(mapStatoToProps, {})(InvoiceFormProducts);
import {
    DASHBOARD
} from '../actions/types';

const initialState = {
    list: []
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DASHBOARD:
            return{
                ...state,
                list: payload
            }
            break;

        default:
            return state;
    }
}
import api from '../utils/api';
import {
    INVOICE_NEW,
    INVOICE_SAVE,
    INVOICE_LIST,
    INVOICE_EROR,
    INVOICE_EROR_HIDE
} from './types';

export const newInvoice = () => async dispatch => {
    try {
        const res = await api.get('/invoices/new');
        dispatch({
            type: INVOICE_NEW,
            payload: res.data.invoiceNumber
        });
    } catch (error) {
        dispatch({
            type: INVOICE_EROR
        })
    }
}

export const saveInvoice = invoice => async dispatch => {
    try {
        const res = await api.post('/invoices/save', invoice)
        dispatch({
            type: INVOICE_SAVE,
            payload: res.data
        });
    } catch (error) {
        dispatch({
            type: INVOICE_EROR
        })
        setTimeout(() => {
            dispatch({
                type: INVOICE_EROR_HIDE
            })
        }, 5000);
    }
}

export const getInvoicesList = () => async dispatch => {
    try {
        const res = await api.get('/invoices/list')
        dispatch({
            type: INVOICE_LIST,
            payload: res.data
        });
    } catch (error) {
        dispatch({
            type: INVOICE_EROR
        })
    }
}
import React from "react";
import { connect } from "react-redux";
import { getProductsLst, newProduct, updateProduct, deleteProduct } from "../../../actions/product";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  Button,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  CardBody
} from "reactstrap";
import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader.js";

class Product extends React.Component {
  state = {
    formModal: false,
    notificationModal: false,
    selectedProduct: null,
    name: '',
    jm: '',
    nettoPerUnit: '',
    sku: '',
    type: 'product',
  };

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    await this.props.getProductsLst();
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  save = e => {
    e.preventDefault();
    let dataToSave = {
      name: this.state.name,
      jm: this.state.jm,
      nettoPerUnit: this.state.nettoPerUnit,
      sku: this.state.sku,
    }
    if(this.state.selectedProduct){
      dataToSave._id = this.state.selectedProduct
      this.props.updateProduct(dataToSave)
    }else{
      this.props.newProduct(dataToSave)
    }
    this.toggleModal("formModal")
  }

  render() {
    return (
      <>
        <SimpleHeader name="Produkty|Usługi" parentName="Produkty|Usługi" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Produkty|Usługi</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={() => {
                          this.setState({
                            selectedProduct: null,
                            name: '',
                            jm: '',
                            nettoPerUnit: '',
                            sku: ''
                          }, () => this.toggleModal("formModal"))
                        }}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Dodaj Produkt|Usługę</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Nazwa</th>
                      <th>JM.</th>
                      <th>Cena Jednostkowa Netto</th>
                      <th>Data dodania</th>
                      <th>Ostatnia zmiana</th>
                      {/* <th>Status Aktywny</th> */}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.props.productsList.map(product => <tr key={product._id} className="table-">
                        <td className="table-user">
                          <b>{product.name}</b>
                        </td>
                        <td>
                          <span className="text-muted">
                            {product.jm}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted">
                            {product.nettoPerUnit}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted">
                            {new Date(product.createdAt).toLocaleString().replace(/\s\d{2,4}:\d{2}:\d{2}$/, '').replace(/,\s*$/, "")}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted">
                            {new Date(product.updatedAt).toLocaleString().replace(/\s\d{2,4}:\d{2}:\d{2}$/, '').replace(/,\s*$/, "")}
                          </span>
                        </td>
                        <td className="table-actions">
                          <i
                            className="fas fa-edit"
                            style={{ marginRight: ".75rem", cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                selectedProduct: product._id,
                                name: product.name,
                                jm: product.jm,
                                nettoPerUnit: product.nettoPerUnit,
                                sku: product.sku
                              }, () => this.toggleModal("formModal"))
                            }}
                          />
                          <i
                            className="fas fa-trash"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                selectedProduct: product._id,
                              }, () => this.toggleModal("notificationModal"))
                            }}
                          />
                        </td>
                      </tr>)
                    }
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent">
                {this.state.selectedProduct ? 'Edytuj':'Dodaj'} Produkt|Usługę
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("formModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form onSubmit={this.save}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: this.state.focusedEmail
                    })}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Nazwa
                    </label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        placeholder="Nazwa"
                        type="text"
                        onFocus={() =>
                          this.setState({ focusedEmail: true })
                        }
                        onBlur={() =>
                          this.setState({ focusedEmail: false })
                        }
                        value={this.state.name}
                        onChange={e=>this.setState({name: e.target.value})}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: this.state.focusedEmail
                    })}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Jednostka Miary (JM)
                    </label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        placeholder="JM."
                        type="text"
                        onFocus={() =>
                          this.setState({ focusedJm: true })
                        }
                        onBlur={() =>
                          this.setState({ focusedJm: false })
                        }
                        value={this.state.jm}
                        onChange={e=>this.setState({jm: e.target.value})}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: this.state.focusedEmail
                    })}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Cena Jednostkowa Netto
                    </label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        placeholder="Cena"
                        type="text"
                        onFocus={() =>
                          this.setState({ focusedNetto: true })
                        }
                        onBlur={() =>
                          this.setState({ focusedNetto: false })
                        }
                        value={this.state.nettoPerUnit}
                        onChange={e=>this.setState({nettoPerUnit: e.target.value})}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: this.state.focusedEmail
                    })}
                  >
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      SKU
                    </label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        placeholder="SKU"
                        type="text"
                        onFocus={() =>
                          this.setState({ focusedSku: true })
                        }
                        onBlur={() =>
                          this.setState({ focusedSku: false })
                        }
                        value={this.state.sku}
                        onChange={e=>this.setState({sku: e.target.value})}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="submit"
                    >
                      Zapisz
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={this.state.notificationModal}
          toggle={() => this.toggleModal("notificationModal")}
        >
          <div className="modal-header">
            <h6
              className="modal-title"
              id="modal-title-notification"
            >
              
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                this.toggleModal("notificationModal")
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">
                Czy napewno chcesz usunąć <b>produkt</b> | <b>usułgę</b> ? 
              </h4>
              <p>
                
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={()=>{
                this.props.deleteProduct(this.state.selectedProduct)
                this.toggleModal("notificationModal")
              }}
            >
              TAK
            </Button>
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                this.toggleModal("notificationModal")
              }
            >
              NIE
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStatoToProps = (state) => ({
  productsList: state.products.productsList,
});

export default connect(mapStatoToProps, { getProductsLst, newProduct, updateProduct, deleteProduct })(Product);

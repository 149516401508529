import api from '../utils/api';
import {
    PRODUCT_ADD,
    PRODUCT_UPDATE,
    PRODUCT_DELETE,
    PRODUCT_LIST,
    PRODUCT_ERROR
} from './types';

export const newProduct = product => async dispatch => {
    try {
        await api.post('/product/add', product);
        dispatch(getProductsLst())
    } catch (error) {
        // dispatch({
        //     type: PRODUCT_ERROR
        // })
    }
}

export const updateProduct = product => async dispatch => {
    try {
        await api.post('/product/update', product)
        dispatch(getProductsLst())
    } catch (error) {
        // dispatch({
        //     type: PRODUCT_ERROR
        // })
    }
}

export const deleteProduct = productId => async dispatch => {
    try {
        await api.post('/product/delete', {productId: productId})
        dispatch(getProductsLst())
    } catch (error) {
        // dispatch({
        //     type: PRODUCT_ERROR
        // })
    }
}

export const getProductsLst = () => async dispatch => {
    try {
        const res = await api.get('/product/list')
        dispatch({
            type: PRODUCT_LIST,
            payload: res.data
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_ERROR
        })
    }
}
import {
    AUTH_ERROR,
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    USER_LOADED
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: null,
    loginFail: null
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
                loginFail: null
            }

        case LOGIN_FAIL:
            return {
                ...state,
                loginFail: payload
            }

        case USER_LOADED:
            return{
                ...state,
                isAuthenticated: true,
                loading: false
            }

        case AUTH_ERROR: 
            return{
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null
            }

        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null
            };

        default:
        return state;
    }
}

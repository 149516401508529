export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const INVOICE_NEW = 'INVOICE_NEW';
export const INVOICE_SAVE = 'INVOICE_SAVE';
export const INVOICE_LIST = 'INVOICE_LIST';
export const INVOICE_EROR = 'INVOICE_EROR';
export const INVOICE_EROR_HIDE = 'INVOICE_EROR_HIDE';

export const COMPANY_CREATE = 'COMPANY_CREATE';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_MY = 'COMPANY_MY';
export const COMPANY_EROR = 'COMPANY_EROR';

export const CUSTOMERS_LIST = 'CUSTOMERS_LIST';

export const DASHBOARD = 'DASHBOARD';

export const PRODUCT_ADD = 'PRODUCT_ADD';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_LIST = 'PRODUCT_LIST';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
import React from 'react';
import { connect } from 'react-redux';
import {getInvoicesList} from '../../../actions/invoice';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Table,
    Container,
    Row,
  } from "reactstrap";
  import SimpleHeader from "components/Headers/SimpleHeader.js";
import invoice from '../invoice';

class InvoiceHistory extends React.Component{
    constructor(props){
        super(props)
    }

    componentDidMount(){
        this.loadData()
    }

    async loadData(){
        await this.props.getInvoicesList();
    }

    render(){
        return(
            <>
            <SimpleHeader name="Faktury" parentName="Faktury" />
            <Container className="mt--6" fluid>
                <Row>
                <div className="col">
                <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Faktury</h3>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th className="sort" data-sort="name" scope="col">
                                Numer faktury
                            </th>
                            <th className="sort" data-sort="name" scope="col">
                                Nabywca
                            </th>
                            <th className="sort" data-sort="name" scope="col">
                                Netto
                            </th>
                            <th className="sort" data-sort="name" scope="col">
                                Brutto
                            </th>
                            <th className="sort" data-sort="creationDate" scope="col">
                                Data wystawienia
                            </th>
                            {/* <th className="sort" data-sort="executionDate" scope="col">
                                Data wykonania
                            </th> */}
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody className="list">
                        {
                            this.props.invoicesList.map(invoice => {
                                let buyer = invoice.buyer ? invoice.buyer[0] : null;
                                let netto=0;
                                let brutto=0;
                                invoice.products.forEach(e => {
                                    netto += Number(e.netto)
                                    brutto += Number(e.brutto)
                                })
                                return(<tr key={invoice._id}>
                                    <th scope="row" >
                                      {invoice.invoiceNumber}
                                    </th>
                                    <td>
                                        {buyer ? buyer.type === 'person' ? `${buyer.firstName} ${buyer.lastName}` : buyer.companyName : ''}
                                    </td>
                                    <td>
                                        {(Math.round((netto) * 100) / 100).toFixed(2).toString()} zł
                                    </td>
                                    <td>
                                        {(Math.round((brutto) * 100) / 100).toFixed(2).toString()} zł
                                    </td>
                                    <td>
                                      {new Date(invoice.creationDate).toLocaleString().replace(/\s\d{2,4}:\d{2}:\d{2}$/, '').replace(/,\s*$/, "")}
                                    </td>
                                    {/* <td>
                                      {new Date(invoice.executionDate).toLocaleString().replace(/\s\d{2,4}:\d{2}:\d{2}$/, '').replace(/,\s*$/, "")}
                                    </td> */}
                                    <td className="text-right">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                            className="btn-icon-only text-light"
                                            color=""
                                            role="button"
                                            size="sm"
                                            >
                                            <i className="fas fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                            <DropdownItem
                                                href="#pablo"
                                                onClick={e => e.preventDefault()}
                                            >
                                              Podgląd
                                            </DropdownItem>
                                            <DropdownItem
                                                href="#pablo"
                                                onClick={e => e.preventDefault()}
                                            >
                                              Korekta
                                            </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                  </nav>
                </CardFooter>
                </Card>
                </div>
                </Row>
            </Container>
            </>
        )
    }
}

const mapStateToProps = state => ({
    invoicesList: state.invoices.invoicesList
})

export default connect(
    mapStateToProps,
    { getInvoicesList }
)(InvoiceHistory);